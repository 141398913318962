import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/working_dir/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Row } from './helpers';
import { Checkbox } from '../../src';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "checkbox"
    }}>{`Checkbox`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import Checkbox from '@mfrm/mfcl/Checkbox'
`}</code></pre>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={Checkbox} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={1} __code={'<Checkbox label=\"Checkbox\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Row,
      Checkbox,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Checkbox label="Checkbox" mdxType="Checkbox" />
    </Playground>
    <h2 {...{
      "id": "size"
    }}>{`Size`}</h2>
    <Playground __position={2} __code={'<Row>\n  <Checkbox size=\"sm\" label=\"Small\" />\n  <Checkbox size=\"md\" label=\"Medium\" />\n  <Checkbox size=\"lg\" label=\"Large\" />\n</Row>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Row,
      Checkbox,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Row mdxType="Row">
    <Checkbox size="sm" label="Small" mdxType="Checkbox" />
    <Checkbox size="md" label="Medium" mdxType="Checkbox" />
    <Checkbox size="lg" label="Large" mdxType="Checkbox" />
  </Row>
    </Playground>
    <h2 {...{
      "id": "label-placement"
    }}>{`Label Placement`}</h2>
    <Playground __position={3} __code={'<Row>\n  <Checkbox labelPlacement=\"top\" label=\"Top label\" />\n  <Checkbox labelPlacement=\"left\" label=\"Left label\" />\n  <Checkbox labelPlacement=\"bottom\" label=\"Bottom label\" />\n  <Checkbox labelPlacement=\"right\" label=\"Right label\" />\n</Row>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Row,
      Checkbox,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Row mdxType="Row">
    <Checkbox labelPlacement="top" label="Top label" mdxType="Checkbox" />
    <Checkbox labelPlacement="left" label="Left label" mdxType="Checkbox" />
    <Checkbox labelPlacement="bottom" label="Bottom label" mdxType="Checkbox" />
    <Checkbox labelPlacement="right" label="Right label" mdxType="Checkbox" />
  </Row>
    </Playground>
    <h2 {...{
      "id": "background-color"
    }}>{`Background Color`}</h2>
    <Playground __position={4} __code={'<Checkbox\n  backgroundColor=\"red\"\n  label=\"Red Background\"\n  labelPlacement=\"right\"\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Row,
      Checkbox,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Checkbox backgroundColor="red" label="Red Background" labelPlacement="right" mdxType="Checkbox" />
    </Playground>
    <h2 {...{
      "id": "checkbox-alignment"
    }}>{`Checkbox Alignment`}</h2>
    <Playground __position={5} __code={'<Checkbox alignment=\"left\" label=\"Left Alignment\" />\n<Checkbox alignment=\"center\" label=\"Center Alignment\" />\n<Checkbox alignment=\"right\" label=\"Right Alignment\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Row,
      Checkbox,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <Checkbox alignment="left" label="Left Alignment" mdxType="Checkbox" />
    <Checkbox alignment="center" label="Center Alignment" mdxType="Checkbox" />
    <Checkbox alignment="right" label="Right Alignment" mdxType="Checkbox" />
    </Playground>
    <h2 {...{
      "id": "ada-checkbox-grouping"
    }}>{`ADA Checkbox Grouping`}</h2>
    <p>{`Wrap "fieldset" around each grouping of checkboxes for ADA.`}</p>
    <Playground __position={6} __code={'<fieldset>\n  <legend>Pick two</legend>\n  <div className=\"checkbox-wrapper\">\n    <Checkbox label=\"Option 1\" labelPlacement=\"right\" />\n    <Checkbox label=\"Option 2\" labelPlacement=\"right\" />\n    <Checkbox label=\"Option 3\" labelPlacement=\"right\" />\n  </div>\n</fieldset>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Row,
      Checkbox,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <fieldset>
    <legend>Pick two</legend>
    <div className="checkbox-wrapper">
      <Checkbox label="Option 1" labelPlacement="right" mdxType="Checkbox" />
      <Checkbox label="Option 2" labelPlacement="right" mdxType="Checkbox" />
      <Checkbox label="Option 3" labelPlacement="right" mdxType="Checkbox" />
    </div>
  </fieldset>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      